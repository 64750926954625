
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UmbrellaPolicy from "@/components/reusable/policy-information/UmbrellaPolicy.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-umbrella-policy",
  components: { UmbrellaPolicy },
  data() {
    return {
      route: variables.SUPERADMIN_COOPERATIVE_ROUTE,
      businessClassRoute: variables.SUPERADMIN_BUSINESS_CLASS_ROUTE,
      postRoute: variables.SUPERADMIN_UMBRELLA_POLICY_ROUTE
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Umbrella Policy", [
        "Administrator",
        "Policies"
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Administrator Umbrella Policy | " + process.env.VUE_APP_TITLE;
  }
});

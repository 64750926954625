
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "umbrella-policy-component",
  components: { Form, Field, ErrorMessage },
  props: {
    route: String,
    businessClassRoute: String,
    postRoute: String,
    returnRoute: String,
  },
  data() {
    return {
      cooperative: "",
      businessClass: "",
      businessType: "",
      packagePublicId: "",
      adminCharge: "",
      cooperatives: [],
      insurancePackages: [],
      businessTypes: [],
      underwriters: [],
      packages: [],
      proportions: {
        underwriter: "",
        percentage: "",
      },
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const { displayDatatable, isEmptyArray } = ResusableFunctions();

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);
            let total = 0;
            // const proportions = Object.keys(values["proportions"]);
            const proportions = values["proportions"];

            proportions.forEach((value) => {
              if (value["[percentage]"] != undefined) {
                total += parseInt(value["[percentage]"]) as number;
              }
            });

            if (isNaN(total) == false && total === 100) {
              // return new Response(formData).text().then(console.log);

              ApiService.setHeader();
              ApiService.post(props.postRoute as string, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
                .then((response) => {
                  variables.toastAlert(response.data.data.message, "success");
                  resetForm();
                  // Redirect back to claims history page
                  router.push({
                    name: props.returnRoute,
                  });
                })
                .catch(function(error) {
                  variables.toastAlert(error.response.data.error, "error");
                });
            } else {
              variables.toastAlert(
                "Underwriters total percentage proportions must be equal to 100",
                "error"
              );
              return;
            }
          }
        }, 2000);
      }
    };

    const formValidator = Yup.object().shape({
      cooperative: Yup.mixed()
        .required()
        .label("Cooperative"),
      businessClass: Yup.mixed()
        .required()
        .label("Business class"),
      businessType: Yup.mixed()
        .required()
        .label("Business type"),
      packagePublicId: Yup.string().required(),
      // proportions: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       underwriter: Yup.string()
      //         .required()
      //         .label("Underwriter"),
      //       percentage: Yup.string()
      //         .required()
      //         .label("Percentage"),
      //     })
      //   )
      //   .strict(),
    });

    return {
      submitButton1,
      formValidator,
      submitForm,
      displayDatatable,
      isEmptyArray,
    };
  },
  created() {
    // Get the list of cooperatives
    this.getCooperatives();

    // Get the list of active IBN packages
    this.getInsurancePackages();
  },
  methods: {
    getCooperatives() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.route}`)
          .then(({ data }) => {
            //Assign data to props
            this.cooperatives = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getInsurancePackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.businessClassRoute}`)
          .then(({ data }) => {
            //Assign data to props
            this.insurancePackages = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getBusinessTypes(event) {
      this.insurancePackages.forEach((item) => {
        if (item["publicId"] === event.target.value) {
          this.businessTypes = item["businessTypes"];
          this.underwriters = item["availableUnderwriters"];

          // if (!this.isEmptyArray(this.underwriters)) {
          //   this.displayDatatable(["basicExample"]);
          // }
        }
      });
    },
    getPackages(event) {
      this.businessTypes.forEach((item) => {
        if (item["publicId"] === event.target.value) {
          this.packages = item["packages"];
        }
      });
    },

    setUnderwriterValue(value, position, event, id) {
      const underwriterValue = document.getElementById(
        "proportions-" + position
      ) as HTMLInputElement;

      const triggerField = document.getElementById(
        event.target.ariaValueText
      ) as HTMLInputElement;

      if (underwriterValue.hasAttribute("disabled")) {
        underwriterValue.removeAttribute("disabled");
        triggerField.removeAttribute("disabled");
      } else {
        underwriterValue.setAttribute("disabled", "disabled");
        triggerField.setAttribute("disabled", "disabled");
        triggerField.value = "";
        this.calculateProportion(event);
      }
    },
    calculateProportion(event) {
      let proportionTotal = 0 as number;
      const inputs = $(".proportion");

      for (let i = 0; i < inputs.length; i++) {
        proportionTotal += variables.convertToInteger($(inputs[i]).val());
      }

      if (isNaN(proportionTotal) == false) {
        if (proportionTotal > 100) {
          return this.$nextTick(() => (event.target.value = 0));
        }
        $("#total-proportion").text(proportionTotal + "%");
      }
    },
  },
});
